import { FaPencilAlt } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  IconButton,
  Flex,
  Button,
  ListItem,
  Text,
  Input,
  Switch,
  useClipboard,
} from "@chakra-ui/react";
import { updateAnswerTitle, deleteAnswer, togglePublicAccess } from "../api";

const AnswerListItem = ({ answer, answers, setAnswers }) => {
  const [isPublic, setIsPublic] = useState(answer.is_public);
  const shareUrl = `${window.location.origin}/view/${answer.answer_id}`; // The URL to share
  const { hasCopied, onCopy } = useClipboard(shareUrl);
  const [editingId, setEditingId] = useState(null); // Track the answer being edited
  const [newTitle, setNewTitle] = useState(""); // Store the updated title temporarily
  const navigate = useNavigate();

  const handleEditTitle = (answerId, currentTitle) => {
    setEditingId(answerId); // Set the current answer as being edited
    setNewTitle(currentTitle); // Set the current title in the input field
  };

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value); // Update the title as the user types
  };

  const handleViewAnswer = (id) => {
    navigate(`/answers/${id}`);
  };

  const handleDeleteAnswer = async (answer_id) => {
    const isConfirmed = window.confirm("정말로 이 답안지를 삭제하시겠습니까?");

    if (!isConfirmed) {
      return; // 사용자가 취소하면 함수 종료
    }
    try {
      await deleteAnswer(answer_id); // Call the delete function
      setAnswers(answers.filter((answer) => answer.answer_id !== answer_id)); // Remove the answer from the state after successful deletion
    } catch (error) {
      console.error("Failed to delete the answer:", error);
      alert("답안지를 삭제하는 데 실패했습니다.");
    }
  };

  const handleSaveTitle = async (answerId) => {
    try {
      // Call an API function to update the answer title (You can define this function in api.js)
      await updateAnswerTitle(answerId, newTitle);

      // Update the local state with the new title
      setAnswers((prevAnswers) =>
        prevAnswers.map((answer) =>
          answer.answer_id === answerId
            ? { ...answer, subject: newTitle }
            : answer
        )
      );
    } catch (error) {
      console.error("Failed to update title:", error);
    }
    setEditingId(null); // Exit edit mode
  };

  const handleTogglePublicAccess = async () => {
    try {
      const response = await togglePublicAccess(answer.answer_id, isPublic);

      if (response.ok) {
        setIsPublic(!isPublic); // Update the local state to reflect the new public status
        console.log(isPublic);
      } else {
        console.error("Failed to update public access");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <ListItem
      key={answer.answer_id}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p="2"
      borderBottom="1px solid gray"
      width="full"
    >
      <Flex alignItems="center" width="30%">
        {editingId === answer.answer_id ? (
          // If this answer is being edited, show an input field
          <Input
            value={newTitle}
            onChange={handleTitleChange}
            onBlur={() => handleSaveTitle(answer.answer_id)} // Save when input loses focus
            autoFocus
            size="sm"
          />
        ) : (
          <>
            <Text>{answer.subject}</Text>
            <Tooltip label="과목 이름 수정" aria-label="Edit title tooltip">
              <IconButton
                aria-label="Edit title"
                icon={<FaPencilAlt />}
                size="sm"
                ml="2"
                variant="ghost" // Optional: set button style
                onClick={() =>
                  handleEditTitle(answer.answer_id, answer.subject)
                } // Enter edit mode
              />
            </Tooltip>
          </>
        )}
      </Flex>
      <Text color="gray.500" fontSize="11px" width="20%">
        {formatDate(answer.modified_at)}
      </Text>
      <Button
        size="xs"
        background="blue.600"
        color={"white"}
        onClick={() => handleViewAnswer(answer.answer_id)}
      >
        보기
      </Button>
      <Flex alignItems="center">
        <Switch isChecked={isPublic} onChange={handleTogglePublicAccess} />
      </Flex>

      <Button
        onClick={onCopy}
        background="white"
        color={"blue.600"}
        ml="4"
        variant="ghost"
        isDisabled={!isPublic}
        size="sm"
      >
        {hasCopied ? "복사됨" : "URL 복사"}
      </Button>

      <Button
        size="sm"
        colorScheme="red"
        onClick={() => handleDeleteAnswer(answer.answer_id)}
      >
        삭제
      </Button>
    </ListItem>
  );
};

export default AnswerListItem;
