import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Button,
  List,
  Text,
  Select,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Grid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
  IconButton,
  Tooltip,
  ListItem,
  Center,
} from "@chakra-ui/react";
import {
  FaPlus,
  FaUserCircle,
  FaSignOutAlt,
  FaQuestionCircle,
} from "react-icons/fa"; // react-icons 사용
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import AnswerListItem from "../components/AnswerListItem";
import { fetchAnswers, deleteUser } from "../api";

const AnswerList = () => {
  const [answers, setAnswers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [examSubject, setExamSubject] = useState("");
  const [timeLimitOption, setTimeLimitOption] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const loadAnswers = async () => {
      try {
        const data = await fetchAnswers(); // Call the function from api.js
        setAnswers(data); // Update the state with fetched answers
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };

    loadAnswers();
  }, []);

  const handleCreateAnswer = () => {
    if (examSubject === "") {
      alert("시험과목을 입력해주세요.");
      return;
    }
    // 제한시간을 '시:분:초' 형식으로 저장
    const timeLimit = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    navigate("/answers/new", {
      state: { examSubject, timeLimitOption, timeLimit },
    });
    onClose();
  };

  const handleLogout = () => {
    // 로그아웃 시 로컬 스토리지에서 토큰 삭제
    localStorage.removeItem("access_token");
    navigate("/login"); // 로그인 페이지로 이동
  };

  const handleDeleteUser = async () => {
    const confirmed = window.confirm(
      "정말로 계정을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
    );

    if (!confirmed) {
      // User canceled the deletion
      return;
    }
    try {
      await deleteUser(); // Call the user deletion service
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      navigate("/login");
    } catch (error) {
      console.error("Failed to delete user:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleProfile = () => {
    navigate("/profile"); // 프로필 페이지로 이동
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      bgGradient="linear(to-r, blue.50, blue.100)"
      p={4}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={6}
        width={"full"}
      >
        <Heading size="lg">ibt 연습장</Heading>

        {/* 유저 프로필 메뉴 */}
        <Flex>
          <Tooltip
            label={`서비스 제공자 연락처 : ibtpracticehelp@gmail.com`}
            aria-label="Contact tooltip"
          >
            <IconButton
              mb="4"
              icon={<FaQuestionCircle />}
              background="transparent"
            />
          </Tooltip>
          <Menu>
            <MenuButton
              as={Button}
              background="blue.500"
              _hover={{ background: "blue.400" }}
              color="white"
              rightIcon={<FaUserCircle />}
            >
              <Flex alignItems="center">
                <Avatar
                  size="sm"
                  name={localStorage.getItem("nickname")}
                  mr="2"
                />
                <Text>프로필</Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleDeleteUser} color="red.500">
                회원 탈퇴 하기
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout} color="red.500">
                로그아웃
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Center mb={6}>
        <Button
          leftIcon={<FaPlus />}
          colorScheme="teal"
          variant="solid"
          size="lg"
          onClick={onOpen}
        >
          새 답안지 만들기
        </Button>
      </Center>
      <Box p="6" rounded="md" shadow="md" width="600px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          작성한 답안지 리스트
        </Heading>

        {/* 답안지 리스트 */}
        <List>
          <ListItem
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="2"
            borderBottom="1px solid gray"
            width="full"
          >
            <Flex alignItems="center" width="30%">
              <Text>과목</Text>
            </Flex>
            <Text color="gray.500" fontSize="11px" width="20%">
              최근 수정 일자
            </Text>

            <Flex alignItems="center" fontSize="12px">
              <Text>공유 여부</Text>
            </Flex>
            <Box width={"130px"} />
          </ListItem>
        </List>

        <List spacing={3}>
          {answers.map((answer) => (
            <AnswerListItem
              answer={answer}
              answers={answers}
              setAnswers={setAnswers}
            />
          ))}
        </List>

        <Flex fontSize="sm" color="gray.600" mt="4" justifyContent="center">
          <Button onClick={() => navigate("/policy")} variant={"ghost"}>
            이용약관
          </Button>
          <Box width={"120px"} />
          <Button onClick={() => navigate("/privacy")} variant={"ghost"}>
            개인정보 처리방침
          </Button>
        </Flex>

        {/* 새 답안지 만들기 모달 */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>새 답안지 만들기</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb="4">
                <FormLabel>시험과목</FormLabel>
                <Input
                  placeholder="시험과목 입력"
                  value={examSubject}
                  onChange={(e) => setExamSubject(e.target.value)}
                />
              </FormControl>

              <FormControl mb="4">
                <FormLabel>제한시간 선택</FormLabel>
                <Select
                  placeholder="제한시간 선택"
                  value={timeLimitOption}
                  onChange={(e) => setTimeLimitOption(e.target.value)}
                >
                  <option value="none">없음</option>
                  <option value="set">설정 시간</option>
                  <option value="cumulative">누적 시간</option>
                </Select>
              </FormControl>

              {/* 설정 시간 옵션이 선택되었을 때 시간 입력란 표시 */}
              {timeLimitOption === "set" && (
                <FormControl mb="4">
                  <FormLabel>제한시간 설정</FormLabel>
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    gap={4}
                    alignItems="center"
                  >
                    {/* 시 입력 */}
                    <NumberInput
                      max={23}
                      min={0}
                      value={hours}
                      onChange={(valueString) =>
                        setHours(parseInt(valueString))
                      }
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text textAlign="center">시간</Text>

                    {/* 분 입력 */}
                    <NumberInput
                      max={59}
                      min={0}
                      value={minutes}
                      onChange={(valueString) =>
                        setMinutes(parseInt(valueString))
                      }
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text textAlign="center">분</Text>
                  </Grid>
                </FormControl>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr="3" onClick={handleCreateAnswer}>
                완료
              </Button>
              <Button onClick={onClose}>취소</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default AnswerList;
